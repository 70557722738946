<template>
  <CBox w="100%" px="70px" bgColor="#fff">
    <Certificate
      v-if="
        currentAppType.type !== 'LOGIC' &&
        currentTest.passed_score >= 70 &&
        currentAppType.certificate_type !== 'uad'
      "
    />
    <CertificateCompetition
      v-else-if="
        currentAppType.type !== 'LOGIC' &&
        currentTest.passed_score >= 70 &&
        currentAppType.certificate_type === 'uad'
      "
    />
    <CBox v-else w="100%" mt="56px" align="center">
      <CText
        v-if="!isLoading"
        fontFamily="Montserrat"
        fontStyle="normal"
        fontSize="16px"
        fontWeight="bold"
        color="fontMain"
      >
        You're not passed in this Devcode Challenge [{{ currentTest.id }}]
      </CText>
    </CBox>
  </CBox>
</template>

<script>
import Certificate from "@/components/Certificate.vue";
import CertificateCompetition from "@/components/CertificateCompetition.vue";
import Swal from "sweetalert2";
import { GET_TEST } from "@/store/test.module";
import { GET_APP_TYPE } from "@/store/app-types.module";

export default {
  components: {
    Certificate,
    CertificateCompetition,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    currentTest() {
      return this.$store.getters.currentTest;
    },
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(GET_TEST, this.$route.params.id);
        await this.$store.dispatch(GET_APP_TYPE, this.currentTest.app_type_id);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        if (err.code !== 401) {
          Swal.fire({
            title: "Oops . .",
            text: err.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
      }
    },
  },
};
</script>
