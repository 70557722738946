import { render, staticRenderFns } from "./CertificateCompetition.vue?vue&type=template&id=5a6942f5"
import script from "./CertificateCompetition.vue?vue&type=script&lang=js"
export * from "./CertificateCompetition.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CStack: require('@chakra-ui/vue').CStack, CSimpleGrid: require('@chakra-ui/vue').CSimpleGrid, CGrid: require('@chakra-ui/vue').CGrid, CImage: require('@chakra-ui/vue').CImage, CDivider: require('@chakra-ui/vue').CDivider})
