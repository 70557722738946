<template>
  <CBox>
    <CBox v-if="['LOGIC', 'PLAYGROUND'].includes(currentAppType.type)">
      <CText
        fontFamily="Montserrat"
        fontStyle="normal"
        fontSize="20px"
        fontWeight="bold"
        color="fontMain"
      >
        Result
      </CText>
      <!-- Layout for desktop -->
      <CBox :d="['none', 'none', 'block', 'block']">
        <CBox class="container">
          <CBox class="progress-container" mx="auto">
            <CBox :style="progressStyle" class="progress-overlay">
              <CBox class="progress-two">
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="20px"
                  fontWeight="normal"
                  color="fontMain"
                >
                  Score
                </CText>
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="40px"
                  fontWeight="semibold"
                  color="fontMain"
                >
                  {{ currentTest.total_point }}
                </CText>
              </CBox>
            </CBox>
          </CBox>
          <CBox m="0px">
            <CBox
              border="10px solid transparent"
              borderLeftColor="#16ABF8"
              backgroundColor="#FBFBFB"
              py="6px"
              pl="15px"
              w="200px"
              borderRadius="8px"
            >
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="16px"
                fontWeight="normal"
                color="fontMain"
              >
                Solved Problem
              </CText>
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="24px"
                fontWeight="semibold"
                color="fontMain"
              >
                {{ currentTest.result.passed }}
              </CText>
            </CBox>
            <CBox
              border="10px solid transparent"
              borderLeftColor="#DEDEDE"
              backgroundColor="#FBFBFB"
              py="6px"
              pl="15px"
              w="200px"
              mt="10px"
              borderRadius="8px"
            >
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="16px"
                fontWeight="normal"
                color="fontMain"
              >
                Unresolved Problem
              </CText>
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="24px"
                fontWeight="semibold"
                color="fontMain"
              >
                {{ currentTest.result.failed }}
              </CText>
            </CBox>
          </CBox>
        </CBox>
      </CBox>

      <!-- Layout for mobile -->
      <CBox :d="['block', 'block', 'none', 'none']">
        <CBox class="progress-container" mx="auto" mt="10px">
          <CBox :style="progressStyle" class="progress-overlay">
            <CBox class="progress-two">
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="20px"
                fontWeight="normal"
                color="fontMain"
              >
                Score
              </CText>
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="40px"
                fontWeight="semibold"
                color="fontMain"
              >
                {{ currentTest.total_point }}
              </CText>
            </CBox>
          </CBox>
        </CBox>
        <CBox mt="15px" class="container-mobile">
          <CBox
            border="10px solid transparent"
            borderLeftColor="#16ABF8"
            backgroundColor="#FBFBFB"
            py="2px"
            pl="8px"
            w="calc(50% - 5px)"
            mr="5px"
            borderRadius="8px"
          >
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="12px"
              fontWeight="semibold"
              color="fontMain"
            >
              Solved Problem
            </CText>
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="24px"
              fontWeight="semibold"
              color="fontMain"
            >
              {{ currentTest.result.passed }}
            </CText>
          </CBox>
          <CBox
            border="10px solid transparent"
            borderLeftColor="#DEDEDE"
            backgroundColor="#FBFBFB"
            py="2px"
            pl="8px"
            w="calc(50% - 5px)"
            ml="5px"
            mt="10px"
            borderRadius="8px"
          >
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="12px"
              fontWeight="semibold"
              color="fontMain"
            >
              Unresolved Problem
            </CText>
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="24px"
              fontWeight="semibold"
              color="fontMain"
            >
              {{ currentTest.result.failed }}
            </CText>
          </CBox>
        </CBox>
      </CBox>
    </CBox>
    <CBox
      v-if="
        currentAppType.type &&
        !['LOGIC', 'PLAYGROUND'].includes(currentAppType.type)
      "
    >
      <CBox
        bgImage="url('/img/bg-certificate.png')"
        bgSize="100% 480px"
        minH="480px"
        mx="auto"
        :d="['none', 'none', 'block', 'block']"
      >
        <CGrid templateColumns="1fr 3fr" :gap="6" w="100%">
          <CBox textAlign="center">
            <CBox h="350px"></CBox>
            <CBox align="center" pr="10px">
              <VueQr
                backgroundColor="#F4F4F4"
                :text="baseUrl + '/job/' + currentTest.id"
                :size="100"
                :margin="0"
                :logoSrc="require('@/assets/img/logo-qr.png')"
              />
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="14px"
                color="activeMenu"
                >ID {{ currentTest.id }}</CText
              >
            </CBox>
          </CBox>
          <CBox>
            <CText
              mt="10px"
              fontFamily="Poppins"
              fontStyle="normal"
              fontSize="24px"
              fontWeight="bolder"
              color="fontMain"
              >Sertifikat Devcode Challenge</CText
            >
            <CText
              mt="20px"
              fontFamily="Poppins"
              fontStyle="normal"
              fontSize="14px"
              fontWeight="bolder"
              color="fontMain"
              >Sertifikat diberikan kepada</CText
            >
            <CText
              fontFamily="Poppins"
              fontStyle="normal"
              fontSize="30px"
              fontWeight="bolder"
              color="activeMenu"
              d="table-cell"
              verticalAlign="middle"
              >{{ currentTest.user.emp_full_name }}</CText
            >
            <CText
              fontFamily="Poppins"
              fontStyle="normal"
              fontSize="12px"
              color="fontMain"
              w="428px"
              >Diberikan pada {{ currentTest.created_at_str }} dengan dasar
              mengikuti Devcode dengan judul {{ currentAppType.name }}.</CText
            >
            <CBox align="center" w="428px">
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="30px"
                fontWeight="bold"
                color="fontMain"
                >LULUS</CText
              >
            </CBox>
            <CText
              fontFamily="Poppins"
              fontStyle="normal"
              fontSize="12px"
              color="fontMain"
              w="428px"
              >Berhasil lulus {{ currentTest.passed_score }}% dari skenario yang
              ada dengan detail :</CText
            >
            <CBox mt="5px">
              <CGrid templateColumns="1fr 3fr" :gap="3" w="350px">
                <CBox
                  w="100%"
                  h="100%"
                  :border="showBorder ? '1px' : '0'"
                  borderRadius="6px"
                  align="center"
                  borderColor="bgMain"
                >
                  <CText
                    v-if="currentAppType.type !== 'LOGIC'"
                    fontFamily="Poppins"
                    fontStyle="normal"
                    fontSize="11px"
                    color="fontMain"
                    fontWeight="bolder"
                    pt="10px"
                    lineHeight="10px"
                    >RESPONSE TIME</CText
                  >
                  <CText
                    v-if="currentAppType.type !== 'LOGIC'"
                    pb="10px"
                    pt="10px"
                    fontFamily="Poppins"
                    fontStyle="normal"
                    fontSize="32px"
                    color="activeMenu"
                    fontWeight="bolder"
                    lineHeight="31px"
                    >{{
                      formatResponseTime(currentTest.total_duration)
                    }}s</CText
                  >
                </CBox>
                <CBox>
                  <CBox
                    w="100%"
                    :border="showBorder ? '1px' : '0'"
                    borderRadius="6px"
                    align="center"
                    borderColor="bgMain"
                  >
                    <CBox mx="auto" d="flex" justifyContent="center" py="10px">
                      <CText
                        fontFamily="Poppins"
                        fontStyle="normal"
                        fontSize="12px"
                        color="fontMain"
                        fontWeight="bolder"
                        >PERINGKAT {{ currentTest.rank }}</CText
                      >
                      <CText
                        pl="10px"
                        fontFamily="Poppins"
                        fontStyle="normal"
                        fontSize="10px"
                        color="fontMain"
                        fontWeight="300"
                        >dari
                        {{ currentTest.total_participants }}
                        peserta</CText
                      >
                    </CBox>
                  </CBox>
                  <CBox pt="5px" v-if="currentAppType.type !== 'LOGIC'">
                    <CSimpleGrid :columns="2" :spacing="2">
                      <CBox
                        d="flex"
                        :border="showBorder ? '1px' : '0'"
                        borderRadius="6px"
                        borderColor="bgMain"
                        w="100%"
                        h="38px"
                      >
                        <CBox mx="auto" h="inherit">
                          <CStack
                            d="table-cell"
                            direction="row"
                            h="inherit"
                            verticalAlign="middle"
                          >
                            <CBox
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                            >
                              <CText
                                d="table-cell"
                                verticalAlign="middle"
                                h="inherit"
                                fontFamily="Poppins"
                                fontStyle="normal"
                                fontSize="14px"
                                color="fontMain"
                                fontWeight="bolder"
                                >PASSED</CText
                              >
                            </CBox>
                            <CBox
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                              pl="10px"
                            >
                              <CText
                                d="table-cell"
                                verticalAlign="middle"
                                h="inherit"
                                fontFamily="Poppins"
                                fontStyle="normal"
                                fontSize="22px"
                                color="passedColor"
                                fontWeight="bolder"
                                >{{ currentTest.result.passed }}</CText
                              >
                            </CBox>
                          </CStack>
                        </CBox>
                      </CBox>
                      <CBox
                        d="flex"
                        :border="showBorder ? '1px' : '0'"
                        borderRadius="6px"
                        borderColor="bgMain"
                        w="100%"
                        h="38px"
                      >
                        <CBox mx="auto" h="inherit">
                          <CStack
                            d="table-cell"
                            direction="row"
                            h="inherit"
                            verticalAlign="middle"
                          >
                            <CBox
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                            >
                              <CText
                                d="table-cell"
                                verticalAlign="middle"
                                h="inherit"
                                fontFamily="Poppins"
                                fontStyle="normal"
                                fontSize="14px"
                                color="fontMain"
                                fontWeight="bolder"
                                >FAILED</CText
                              >
                            </CBox>
                            <CBox
                              d="table-cell"
                              verticalAlign="middle"
                              h="inherit"
                              pl="10px"
                            >
                              <CText
                                d="table-cell"
                                verticalAlign="middle"
                                h="inherit"
                                fontFamily="Poppins"
                                fontStyle="normal"
                                fontSize="22px"
                                color="failedColor"
                                fontWeight="bolder"
                                >{{ currentTest.result.failed }}</CText
                              >
                            </CBox>
                          </CStack>
                        </CBox>
                      </CBox>
                    </CSimpleGrid>
                  </CBox>
                </CBox>
              </CGrid>
            </CBox>
            <CBox h="20px" />
            <CBox w="200px" align="center">
              <CImage h="70px" mx="auto" />
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="14px"
                color="fontMain"
                fontWeight="800"
                >Rizqinofa Putra M</CText
              >
              <CCBoxider borderColor="borderBox" />
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="12px"
                color="fontMain"
                fontWeight="bolder"
                >Devcode Challenge Committee</CText
              >
            </CBox>
          </CBox>
        </CGrid>
      </CBox>

      <!-- mobile layout -->
      <CBox
        :d="['block', 'block', 'none', 'none']"
        border="1px solid"
        borderColor="bgMain"
        p="1em"
        borderRadius="5px"
        align="center"
      >
        <CText
          mt="10px"
          fontFamily="Poppins"
          fontStyle="normal"
          fontSize="16px"
          fontWeight="bolder"
          color="fontMain"
          >Sertifikat Devcode Challenge</CText
        >
        <CText
          mt="20px"
          fontFamily="Poppins"
          fontStyle="normal"
          fontSize="14px"
          fontWeight="bolder"
          color="fontMain"
          >Sertifikat diberikan kepada</CText
        >
        <CText
          fontFamily="Poppins"
          fontStyle="normal"
          fontSize="20px"
          fontWeight="bolder"
          color="activeMenu"
          d="table-cell"
          verticalAlign="middle"
          >{{ currentTest.user.emp_full_name }}</CText
        >
        <CText
          whiteSpace="wrap"
          fontFamily="Poppins"
          fontStyle="normal"
          fontSize="12px"
          color="fontMain"
          >Diberikan pada {{ currentTest.created_at_str }} dengan dasar
          mengikuti Devcode dengan judul {{ currentAppType.name }}.</CText
        >
        <CBox align="center">
          <CText
            fontFamily="Poppins"
            fontStyle="normal"
            fontSize="30px"
            fontWeight="bold"
            color="fontMain"
            py="0.5em"
            >LULUS</CText
          >
        </CBox>
        <CText
          fontFamily="Poppins"
          fontStyle="normal"
          fontSize="12px"
          color="fontMain"
          >Berhasil lulus {{ currentTest.passed_score }}% dari skenario yang ada
          dengan detail :</CText
        >
        <CBox mt="5px">
          <CGrid templateColumns="12fr" :gap="3">
            <CBox
              w="100%"
              h="100%"
              :border="showBorder ? '1px' : '0'"
              borderRadius="6px"
              align="center"
              borderColor="bgMain"
            >
              <CText
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="11px"
                color="fontMain"
                fontWeight="bolder"
                pt="10px"
                lineHeight="10px"
                >RESPONSE TIME</CText
              >
              <CText
                pb="10px"
                pt="10px"
                fontFamily="Poppins"
                fontStyle="normal"
                fontSize="32px"
                color="activeMenu"
                fontWeight="bolder"
                lineHeight="31px"
                >{{ formatResponseTime(currentTest.total_duration) }}s</CText
              >
            </CBox>
            <CBox>
              <CBox
                w="100%"
                :border="showBorder ? '1px' : '0'"
                borderRadius="6px"
                align="center"
                borderColor="bgMain"
              >
                <CBox mx="auto" d="flex" justifyContent="center" py="10px">
                  <CText
                    fontFamily="Poppins"
                    fontStyle="normal"
                    fontSize="12px"
                    color="fontMain"
                    fontWeight="bolder"
                    >PERINGKAT {{ currentTest.rank }}</CText
                  >
                  <CText
                    pl="10px"
                    fontFamily="Poppins"
                    fontStyle="normal"
                    fontSize="10px"
                    color="fontMain"
                    fontWeight="300"
                    >dari
                    {{ currentTest.total_participants }}
                    peserta</CText
                  >
                </CBox>
              </CBox>
              <CBox pt="5px">
                <CSimpleGrid :columns="2" :spacing="2">
                  <CBox
                    d="flex"
                    :border="showBorder ? '1px' : '0'"
                    borderRadius="6px"
                    borderColor="bgMain"
                    w="100%"
                    h="38px"
                  >
                    <CBox mx="auto" h="inherit">
                      <CStack
                        d="table-cell"
                        direction="row"
                        h="inherit"
                        verticalAlign="middle"
                      >
                        <CBox d="table-cell" verticalAlign="middle" h="inherit">
                          <CText
                            d="table-cell"
                            verticalAlign="middle"
                            h="inherit"
                            fontFamily="Poppins"
                            fontStyle="normal"
                            fontSize="14px"
                            color="fontMain"
                            fontWeight="bolder"
                            >PASSED</CText
                          >
                        </CBox>
                        <CBox
                          d="table-cell"
                          verticalAlign="middle"
                          h="inherit"
                          pl="10px"
                        >
                          <CText
                            d="table-cell"
                            verticalAlign="middle"
                            h="inherit"
                            fontFamily="Poppins"
                            fontStyle="normal"
                            fontSize="22px"
                            color="passedColor"
                            fontWeight="bolder"
                            >{{ currentTest.result.passed }}</CText
                          >
                        </CBox>
                      </CStack>
                    </CBox>
                  </CBox>
                  <CBox
                    d="flex"
                    :border="showBorder ? '1px' : '0'"
                    borderRadius="6px"
                    borderColor="bgMain"
                    w="100%"
                    h="38px"
                  >
                    <CBox mx="auto" h="inherit">
                      <CStack
                        d="table-cell"
                        direction="row"
                        h="inherit"
                        verticalAlign="middle"
                      >
                        <CBox d="table-cell" verticalAlign="middle" h="inherit">
                          <CText
                            d="table-cell"
                            verticalAlign="middle"
                            h="inherit"
                            fontFamily="Poppins"
                            fontStyle="normal"
                            fontSize="14px"
                            color="fontMain"
                            fontWeight="bolder"
                            >FAILED</CText
                          >
                        </CBox>
                        <CBox
                          d="table-cell"
                          verticalAlign="middle"
                          h="inherit"
                          pl="10px"
                        >
                          <CText
                            d="table-cell"
                            verticalAlign="middle"
                            h="inherit"
                            fontFamily="Poppins"
                            fontStyle="normal"
                            fontSize="22px"
                            color="failedColor"
                            fontWeight="bolder"
                            >{{ currentTest.result.failed }}</CText
                          >
                        </CBox>
                      </CStack>
                    </CBox>
                  </CBox>
                </CSimpleGrid>
              </CBox>
            </CBox>
          </CGrid>
        </CBox>
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import config from "@/ConfigProvider";
import VueQr from "vue-qr";

export default {
  components: {
    VueQr,
  },
  props: {
    showBorder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      baseUrl: config.value("DEVCODE_WEB_URL"),
    };
  },
  computed: {
    currentTest() {
      return this.$store.getters.currentTest;
    },
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
    progressStyle() {
      const percentage = this.currentTest.passed_score / 100;
      const angle = percentage * 360;
      return {
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        border: "",
        backgroundImage: `conic-gradient(#16abf8 0deg ${angle}deg, #dedede ${angle}deg 360deg)`,
        borderImageSlice: "1",
        position: "absolute",
        top: "0",
        left: "0",
        animation: "progressAnimation 1s ease",
      };
    },
  },
  methods: {
    formatResponseTime(value) {
      if (value) {
        let val = (Math.round((value / 1000) * 100) / 100).toFixed(1);
        return val.toString().replace(".", ",").replace(",0", "");
      }
      return null;
    },
  },
};
</script>

<style>
.progress-container {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: relative;
}

.container {
  margin-top: 20px;
  margin-right: 100px;
  margin-left: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: relative;
}

.container-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: relative;
}

.progress-two {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress-overlay {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center;
}

@keyframes progressAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(var(--progress-angle, 0deg));
  }
}

/* Use CSS custom properties to dynamically set the progress angle */
.progress-overlay {
  --progress-angle: 0;
  animation: progressAnimation 1s ease;
}
</style>
