var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',{attrs:{"w":"100%","px":"70px","bgColor":"#fff"}},[(
      _vm.currentAppType.type !== 'LOGIC' &&
      _vm.currentTest.passed_score >= 70 &&
      _vm.currentAppType.certificate_type !== 'uad'
    )?_c('Certificate'):(
      _vm.currentAppType.type !== 'LOGIC' &&
      _vm.currentTest.passed_score >= 70 &&
      _vm.currentAppType.certificate_type === 'uad'
    )?_c('CertificateCompetition'):_c('CBox',{attrs:{"w":"100%","mt":"56px","align":"center"}},[(!_vm.isLoading)?_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"16px","fontWeight":"bold","color":"fontMain"}},[_vm._v(" You're not passed in this Devcode Challenge ["+_vm._s(_vm.currentTest.id)+"] ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }